import axios from "axios";

let instance = axios.create({
  baseURL: "https://api.hyperauth.app/user",
});

export const verifyEmail = (data) => {
  return instance.post("/verifyEmail", data);
};

export const confirmPasswordReset = (data) => {
  return instance.post("/confirmPasswordReset", data);
};
