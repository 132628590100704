import React, { useState, useEffect } from "react";
import DarkLogo from "./assets/wideLogoDark.png";
import AnimatedMail from "./animatedMail";
import AnimatedError from "./animatedError";
import AnimatedSuccess from "./animatedSuccess";
import { useLocation } from "react-router";
import { verifyEmail } from "./actions/axios";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const VerifyEmail = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  let query = useQuery();
  useEffect(() => {
    let tmpToken = query.get("token");
    let tmpEmail = query.get("email");
    if (tmpToken && tmpEmail) {
      setTimeout(() => {
        runVerification(tmpEmail, tmpToken);
      }, 1200);
    } else {
      console.log("missing params.");
    }
  }, []);

  const runVerification = (email, token) => {
    if (!loading) {
      setLoading(true);
      verifyEmail({ token, email })
        .then((res) => {
          setSuccess(true);
          let agent = navigator.userAgent.toLowerCase();
          if (agent.includes("iphone")) {
            window.location.href = "hyperauth://emailVerified";
          } else if (agent.includes("android")) {
            window.location.href = "hyperauth://emailVerified";
          }
        })
        .catch((err) => {
          setError(err.response.data.message);
        });
    }
  };

  return (
    <div
      style={{
        background: "#d2d2cf",
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          background: "white",
          minWidth: "600px",
          width: "600px",
          height: "700px",
          borderRadius: "20px",
          padding: "5px 25px",
          boxShadow:
            "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            marginTop: "20px",
          }}
        >
          <img
            src={DarkLogo}
            alt="logo"
            style={{ width: "auto", height: "120px" }}
          />
        </div>
        {error ? (
          <>
            <div style={{ width: "70%", margin: "0 auto" }}>
              <AnimatedError />
            </div>
            <h1 style={{ fontWeight: 700, color: "#f20c2e" }}>ERROR</h1>
            <p>{error}</p>
          </>
        ) : success ? (
          <>
            <div style={{ width: "70%", margin: "0 auto" }}>
              <AnimatedSuccess />
            </div>
            <p
              style={{
                fontSize: "1.5em",
                fontWeight: 500,
                margin: 0,
                marginBottom: "10px",
              }}
            >
              Email Verified Successfully
            </p>
          </>
        ) : (
          <>
            <div style={{ width: "70%", margin: "0 auto" }}>
              <AnimatedMail />
            </div>
            <p
              style={{
                fontSize: "1.5em",
                fontWeight: 500,
                margin: 0,
                marginBottom: "10px",
              }}
            >
              Verifying your email address
            </p>
            <p style={{ fontSize: "1em", fontWeight: 300, margin: 0 }}>
              This may take a moment
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default VerifyEmail;
