import React from "react";
import styled from "styled-components";
import Lottie from "lottie-react";
import successAnimation from "./assets/successAnimation.json";

const AnimatedSuccess = () => {
  return (
    <AnimationWrap>
      <Lottie
        animationData={successAnimation}
        autoplay={true}
        loop={false}
        size={60}
      />
    </AnimationWrap>
  );
};

const AnimationWrap = styled.div`
  width: 100%;
  display: flex,
  justify-content: center,
  align-items: center,
  svg {
    width: 150px;
    height: 150px;
  }
`;

export default AnimatedSuccess;
