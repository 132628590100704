import React, { useEffect, useState } from "react";
import DarkLogo from "./assets/wideLogoDark.png";
import AnimatedPassword from "./animatedPassword";
import AnimatedSuccess from "./animatedSuccess";
import { useLocation } from "react-router";
import { Input, Button } from "antd";
import { passwordStrength } from "check-password-strength";
import { confirmPasswordReset } from "./actions/axios";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ResetPassword = () => {
  let [token, setToken] = useState(null);
  let [email, setEmail] = useState(null);
  let [error, setError] = useState(null);
  let [password, setPassword] = useState(null);
  let [confirmPassword, setConfirmPassword] = useState(null);
  let [success, setSuccess] = useState(null);

  let query = useQuery();
  useEffect(() => {
    let tmpToken = query.get("token");
    let tmpEmail = query.get("email");
    if (tmpToken && tmpEmail) {
      setToken(tmpToken);
      setEmail(tmpEmail);
    } else {
      console.log("missing params.");
    }
  }, []);

  const updatePass = (e) => {
    setPassword(e.target.value);
    setError(null);
  };

  const updateConfirmPass = (e) => {
    setConfirmPassword(e.target.value);
    setError(null);
  };

  const passwordStrengthOptions = [
    {
      id: 0,
      value: "Too weak",
      minDiversity: 0,
      minLength: 0,
    },
    {
      id: 1,
      value: "Weak",
      minDiversity: 2,
      minLength: 8,
    },
    {
      id: 2,
      value: "Medium",
      minDiversity: 4,
      minLength: 12,
    },
    {
      id: 3,
      value: "Strong",
      minDiversity: 4,
      minLength: 16,
    },
  ];

  const submitPasswords = () => {
    if (password === confirmPassword) {
      let strength = passwordStrength(password, passwordStrengthOptions);
      if (strength.id >= 1) {
        setError(null);
        confirmPasswordReset({
          token: token,
          email: email,
          newPassword: password,
        })
          .then((res) => {
            setSuccess(true);
            let agent = navigator.userAgent.toLowerCase();
            if (agent.includes("iphone")) {
              window.location.href = "hyperauth://passwordReset";
            } else if (agent.includes("android")) {
              window.location.href = "hyperauth://passwordReset";
            }
          })
          .catch((err) => {
            setError(err.response.data.message);
          });
      } else {
        setTimeout(() => {
          setError(
            "Password is too weak, please use at least 8 characters, combining uppercase and lowercase letters with numbers and symbols."
          );
        }, 700);
      }
    } else {
      setTimeout(() => {
        setError("Passwords do not match.");
      }, 700);
    }
  };

  return (
    <div
      style={{
        background: "#d2d2cf",
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          background: "white",
          minWidth: "600px",
          width: "600px",
          height: "600px",
          borderRadius: "20px",
          padding: "5px 25px",
          boxShadow:
            "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            marginTop: "20px",
          }}
        >
          <img
            src={DarkLogo}
            alt="logo"
            style={{ width: "auto", height: "120px" }}
          />
        </div>
        {success ? (
          <>
            <div style={{ width: "70%", margin: "0 auto" }}>
              <AnimatedSuccess />
            </div>
            <p
              style={{
                fontSize: "1.5em",
                fontWeight: 500,
                margin: 0,
                marginBottom: "10px",
              }}
            >
              Email Verified Successfully
            </p>
          </>
        ) : (
          <>
            <div style={{ width: "200px", height: "150px", margin: "0 auto" }}>
              <AnimatedPassword />
            </div>
            <p
              style={{
                fontSize: "1.5em",
                fontWeight: 400,
                margin: 0,
                marginBottom: "10px",
                zIndex: "10",
                marginTop: "-40px",
                marginBottom: "30px",
              }}
            >
              Reset Your Password
            </p>
            <p
              style={{
                textAlign: "left",
                width: "100%",
                margin: 0,
                marginBottom: "10px",
              }}
            >
              New Password
            </p>
            <Input
              type="password"
              placeholder="New Password"
              style={{ marginBottom: "10px" }}
              onChange={updatePass}
            />
            <p
              style={{
                textAlign: "left",
                width: "100%",
                margin: 0,
                marginBottom: "10px",
              }}
            >
              Confirm New Password
            </p>
            <Input
              type="password"
              placeholder="Confirm Password"
              style={{ marginBottom: "30px" }}
              onChange={updateConfirmPass}
            />
            <div style={{ width: "100%" }}>
              <Button
                type="primary"
                style={{ width: "30%", float: "right" }}
                onClick={() => submitPasswords()}
              >
                Submit
              </Button>
            </div>
            <p style={{ color: "#f20c2e", fontWeight: 500 }}>
              {error ? error : null}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
