import React from "react";
import styled from "styled-components";
import Lottie from "lottie-react";
import errorAnimation from "./assets/errorAnimation.json";

const AnimatedError = () => {
  return (
    <AnimationWrap>
      <Lottie
        animationData={errorAnimation}
        autoplay={true}
        loop={true}
        size={60}
      />
    </AnimationWrap>
  );
};

const AnimationWrap = styled.div`
  width: 100%;
  display: flex,
  justify-content: center,
  align-items: center,
  svg {
    width: 150px;
    height: 150px;
  }
`;

export default AnimatedError;
