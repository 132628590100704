import React from "react";
import styled from "styled-components";
import Lottie from "lottie-react";
import mailAnimation from "./assets/mailAnimation.json";

const AnimatedMail = () => {
  return (
    <AnimationWrap>
      <Lottie
        animationData={mailAnimation}
        autoplay={true}
        loop={true}
        size={60}
      />
    </AnimationWrap>
  );
};

const AnimationWrap = styled.div`
  width: 100%;
  display: flex,
  justify-content: center,
  align-items: center,
  svg {
    width: 150px;
    height: 150px;
  }
`;

export default AnimatedMail;
