import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import VerifyEmail from "./verifyEmail";
import ResetPassword from "./resetPassword";
import Redirect from "./redirect";

function App() {
  return (
    <>
      <Routes>
        <Route path="verifyEmail" element={<VerifyEmail />} />
        <Route path="resetPassword" element={<ResetPassword />} />
        <Route index element={<Redirect />} />
      </Routes>
    </>
  );
}

export default App;
